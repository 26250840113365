import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { WidgetDataModel, WidgetType } from '@/models/Widgets';
import WidgetsAPI from '@/network/WidgetsAPI';

import useArtist from '../artist/useArtist';
import useAccountContext from '../context/useAccountContext';

const useWidget = ({ type }: { type: WidgetType }) => {
  const { accountId } = useAccountContext();
  const { artist } = useArtist();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<WidgetDataModel> | null>(
    () => {
      if (!accountId) return null;
      if (!artist && (type === 'social-insights' || type === 'music-insights')) return null;

      return `widgets-data-${accountId}-type-${type}`;
    },
    async () => {
      if (!accountId) return null;
      if (!artist && (type === 'social-insights' || type === 'music-insights')) return null;

      return await WidgetsAPI.getWidgetData({ accountId, type });
    }
  );

  return {
    widget: data?.data,
    widgetIsLoading: isLoading,
    widgetError: error,
    refetchWidget: mutate,
  };
};

export default useWidget;
