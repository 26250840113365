import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { WidgetAPIModel } from '@/models/Widgets';
import WidgetsAPI from '@/network/WidgetsAPI';

import useAccountContext from '../context/useAccountContext';

const useWidgets = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<WidgetAPIModel> | null>(
    () => {
      if (!accountId) return null;

      return `widgets-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await WidgetsAPI.getWidgets({ accountId });
    }
  );

  return {
    widgets: data?.data,
    widgetsIsLoading: isLoading,
    widgetsError: error,
    refetchWidgets: mutate,
  };
};

export default useWidgets;
