import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { PartnerPerk } from '@/models/PartnerPerks';
import PartnerPerksAPI from '@/network/PartnerPerksAPI';

import useAccountContext from '../context/useAccountContext';

const usePartnerPerks = () => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<PartnerPerk[]> | null>(
    () => {
      if (!accountId) return null;

      return `partner-perks-data-${accountId}`;
    },
    async () => {
      if (!accountId) return null;

      return await PartnerPerksAPI.getPartnerPerks({ accountId });
    }
  );

  return {
    partnerPerks: data?.data,
    partnerPerksIsLoading: isLoading,
    partnerPerksError: error,
    refetchPartnerPerks: mutate,
  };
};

export default usePartnerPerks;
