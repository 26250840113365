import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { RegisteredWalkthroughs } from '@/constants/WalkthroughConstants';
import useBreakpoints from '@/hooks/utility/useBreakpoints';
import { VIEW_STATE } from '@/models/Enums';
import { Flags, ViewState, Walkthrough, WalkthroughConfig } from '@/models/Walkthrough';
import AccountAPI from '@/network/AccountAPI';
import { handleApiError } from '@/utility/api';

import useAccountContext from '../context/useAccountContext';
import useReleaseCycles from '../release-cycles/useReleaseCycles';

const useWalkthroughManager = () => {
  const { t } = useTranslation();
  const { account, accountId, refetchAccount } = useAccountContext();
  const location = useLocation();
  const { breakpointHit } = useBreakpoints();
  const { releaseCycles } = useReleaseCycles({});

  const [walkthroughStates, setWalkthroughStates] = useState<Record<string, ViewState>>({});
  const [viewState, setViewState] = useState<ViewState | null>(null);

  const [walkthrough, setWalkthrough] = useState<Walkthrough | null>(null);

  const [flags, setFlags] = useState<Flags>({} as Flags);

  // Get the account's walkthrough states
  useEffect(() => {
    if (account) {
      setWalkthroughStates(account.config.walkthroughConfiguration as Record<string, ViewState>);
    }
  }, [account]);

  // Set the view state based on the walkthrough state
  useEffect(() => {
    if (!walkthrough?.id) {
      setViewState(null);
      return;
    }

    const walkthroughState = walkthroughStates[walkthrough?.id];
    if (walkthroughState) {
      setViewState(walkthroughState);
    } else {
      setViewState(null);
    }
  }, [walkthrough?.id, walkthroughStates]);

  // Wrapper for setting the walkthrough view state and updating the account
  const setWalkthroughViewState = useCallback(
    async (state: ViewState) => {
      if (!walkthrough || !accountId) {
        return;
      }

      try {
        setWalkthroughStates((prev) => ({
          ...prev,
          [walkthrough.id]: state,
        }));

        await AccountAPI.UpdateWalkthroughStatus({
          accountId,
          status: {
            type: walkthrough.id,
            status: state,
          },
        });

        await refetchAccount();
      } catch (error: unknown) {
        handleApiError({
          error,
          customMessage: t('WALKTHROUGH.ERRORS.SET-VIEW-STATE'),
        });
      }
    },
    [accountId, refetchAccount, t, walkthrough]
  );

  // Set flag for accountCreatedDate
  useEffect(() => {
    if (!account) {
      return;
    }

    const accountCreatedDate = new Date(account.createdAt);
    const currentDate = new Date();
    const diff = currentDate.getTime() - accountCreatedDate.getTime();
    const minutes = diff / (1000 * 60);
    const isNewAccount = minutes < 30;

    setFlags((prev) => ({ ...prev, isNewAccount }));
  }, [account]);

  // Set flag for activeReleaseCycle
  useEffect(() => {
    if (releaseCycles?.length) {
      setFlags((prev) => ({ ...prev, activeReleaseCycle: true }));
    } else {
      setFlags((prev) => ({ ...prev, activeReleaseCycle: false }));
    }
  }, [releaseCycles?.length]);

  const resolveWalkthrough = useCallback(
    (walkthroughConfig: WalkthroughConfig | Walkthrough, flags: Flags | null): Walkthrough | null => {
      if (flags && flags.activeReleaseCycle === false && window.location.pathname === '/release-cycles') {
        return null;
      }

      if (!walkthroughConfig || !flags) {
        return null;
      }

      const resolvedSteps = walkthroughConfig.steps
        .map((step) => {
          if (
            breakpointHit &&
            typeof step === 'function' &&
            step !== null &&
            step(flags)?.target === '#nav-bar-item-playlisting'
          ) {
            return null;
          }
          if (typeof step === 'function') {
            return step(flags);
          }
          return step;
        })
        .filter((step) => step != null);

      const resolvedStartModal = walkthroughConfig.startModal
        ? typeof walkthroughConfig.startModal === 'function'
          ? walkthroughConfig.startModal(flags)
          : walkthroughConfig.startModal
        : null;

      const resolvedEndModal = walkthroughConfig.endModal
        ? typeof walkthroughConfig.endModal === 'function'
          ? walkthroughConfig.endModal(flags)
          : walkthroughConfig.endModal
        : null;

      return {
        ...walkthroughConfig,
        startModal: resolvedStartModal,
        endModal: resolvedEndModal,
        steps: resolvedSteps,
      } as Walkthrough;
    },
    [breakpointHit]
  );

  // Resolve the walkthrough based on the route
  useEffect(() => {
    if (!account) {
      return;
    }

    const foundWalkthrough = RegisteredWalkthroughs.find((w) => location.pathname.startsWith(w.startRoute));
    if (foundWalkthrough) {
      // If the view state is not unseen, don't bother fully resolving the walkthrough
      if (viewState !== VIEW_STATE.UNSEEN) {
        setWalkthrough({
          id: foundWalkthrough.id,
          startRoute: foundWalkthrough.startRoute,
          steps: [],
        });
      }
      const resolvedWalkthrough = resolveWalkthrough(foundWalkthrough, flags);
      setWalkthrough(resolvedWalkthrough);
    } else {
      setWalkthrough(null); // Reset if no walkthrough matches the route
    }
  }, [location.pathname, resolveWalkthrough, flags, account, viewState, releaseCycles?.length]);

  return { walkthrough, viewState, setViewState: setWalkthroughViewState };
};

export default useWalkthroughManager;
