import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ReleaseCycleTaskCompletionStatusAPIModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import useAccountContext from '../context/useAccountContext';

const useReleaseCycleTaskCompletionStats = ({ scheduleId }: { scheduleId: string }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } =
    useSWRImmutable<AxiosResponse<ReleaseCycleTaskCompletionStatusAPIModel> | null>(
      () => {
        if (!accountId) return null;

        return `release-cycles-completion-stats-data-${accountId}-scheduleId-${scheduleId}`;
      },
      async () => {
        if (!accountId) return null;

        return await ReleaseCyclesAPI.getReleaseCycleTaskCompletionStatuses({ accountId, scheduleId });
      }
    );

  return {
    releaseCyclesCompletionStats: data?.data.taskStats,
    releaseCyclesCompletionStatsIsLoading: isLoading,
    releaseCyclesCompletionStatsError: error,
    refetchReleaseCyclesCompletionStats: mutate,
  };
};

export default useReleaseCycleTaskCompletionStats;
