import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ReleaseCycleModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import useAccountContext from '../context/useAccountContext';

const useReleaseCycles = ({ isCompleted }: { isCompleted?: boolean }) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ReleaseCycleModel[]> | null>(
    () => {
      if (!accountId) return null;

      return `release-cycles-data-${accountId}-isCompleted-${isCompleted}`;
    },
    async () => {
      if (!accountId) return null;

      return await ReleaseCyclesAPI.getReleaseCycles({ accountId, isCompleted });
    }
  );

  return {
    releaseCycles: data?.data,
    releaseCyclesIsLoading: isLoading,
    releaseCyclesError: error,
    refetchReleaseCycles: mutate,
  };
};

export default useReleaseCycles;
