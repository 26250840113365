import { useContext } from 'react';

import { AnimationContext } from '@/contexts/AnimationContext';

const useAnimationContext = () => {
  const context = useContext(AnimationContext);
  if (!context) {
    throw new Error('useAnimationContext must be used within an AnimationProvider');
  }
  return context;
};

export default useAnimationContext;
