import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ReleaseCycleScheduleAPIModel } from '@/models/ReleaseCycles';
import ReleaseCyclesAPI from '@/network/ReleaseCyclesAPI';

import useAccountContext from '../context/useAccountContext';

const useReleaseCycleSchedule = ({
  scheduleId,
  startDate,
  endDate,
}: {
  scheduleId: string;
  startDate?: string;
  endDate?: string;
}) => {
  const { accountId } = useAccountContext();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ReleaseCycleScheduleAPIModel> | null>(
    () => {
      if (!accountId) return null;

      return `release-cycles-schedule-data-${accountId}-scheduleId-${scheduleId}`;
    },
    async () => {
      if (!accountId) return null;

      if (!scheduleId) {
        return await ReleaseCyclesAPI.getAccountReleaseCycleSchedules({ accountId });
      }
      return await ReleaseCyclesAPI.getReleaseCycleSchedule({ accountId, scheduleId, startDate, endDate });
    }
  );

  const refetchAllSchedules = async (): Promise<void> => {
    await mutate(async (currentData?: AxiosResponse<ReleaseCycleScheduleAPIModel> | null) => {
      if (!currentData) return null;

      // Extract the key from the Axios response's config
      const key = currentData.config?.url;
      if (!key || !key.startsWith(`release-cycles-schedule-data-${accountId}-scheduleId-`)) {
        return currentData;
      }

      // Extract scheduleId from the key
      const extractedScheduleId = key.split('-scheduleId-')[1];
      if (!extractedScheduleId) return null;

      // Fetch updated data and return it
      return await ReleaseCyclesAPI.getReleaseCycleSchedule({
        accountId: accountId!,
        scheduleId: extractedScheduleId,
        startDate: undefined,
        endDate: undefined,
      });
    });
  };

  return {
    releaseCycleSchedule: data?.data.schedule,
    releaseCycleScheduleIsLoading: isLoading,
    releaseCycleScheduleError: error,
    refetchReleaseCycleSchedule: mutate,
    refetchAllSchedules,
  };
};

export default useReleaseCycleSchedule;
