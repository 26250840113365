import { useContext } from 'react';

import { WalkthroughContext, WalkthroughContextModel } from '../../contexts/WalkthroughContext';

export const useWalkthrough = (): WalkthroughContextModel => {
  const context = useContext(WalkthroughContext);
  if (!context) {
    throw new Error('useWalkthrough must be used within a WalkthroughProvider');
  }
  return context;
};
